import React, { Component, useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Radio,
  Select,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
} from 'antd';
import Footer from '../../../components/layout-components/modal/Footer';
import { UploadOutlined } from '@ant-design/icons';
import API from '../../../api';
import {Equipment,confirmation} from '../../../components/EquipmentCheck/comp/equipmentCheckinForm'
import { assendingSort,TextCapitalize,TextUpperCase,useWindowDimensions } from '../../../utils/helper';
import { connect } from 'react-redux';
import { fetchYardList } from '../../../actions/yardLocationAction';
import { CustomerList } from '../../../actions/customerAction';
import { getAssetList, getInYardList,deleteByAssetTxnsId } from '../../../actions/myYardAction';
import {getWashStatus,getDashboardCount,getTicketAwaiting} from '../../../actions/dashboardAction'
import _ from 'lodash';
import PopOver from '../../../components/EquipmentCheck/PopOver';
import AddPo from '../../../components/EquipmentCheck/AddPo'
import { usePosition } from '../../../utils/usePosition';
// import ImageCompression from '../../utils/imageCompress';
import imageCompression from 'browser-image-compression';
import { AppLayout } from '../../../components/layout';

const { Option } = Select;

const RegistrationForm = (props) => {
  const token =
    typeof window !== 'undefined' ? localStorage.getItem('token') : '';
  const { GATSBY_BACKEND_URL } = process.env;
  
  const file = {
    name: 'file',
    lable: 'SDS',
    action: `${GATSBY_BACKEND_URL}image/upload`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    type: 'upload',
    col: 12,
    rules: [
      {
        // required: true,
        message: 'Please upload sds!',
      },
    ],
  };
  const [form] = Form.useForm();
  const [equipment, setEquipment] = useState(Equipment());
  const [visible, setVisible] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [rejectPop, setRejectPop] = useState(false);
  const [clientDetail, setClientDetail] = useState(null);
  const {
    imageUpdate,
    imageDelete,
    getUserDetails,
    poNumber,
    assetSearch,
    equipmentCheckin,
    getEquipmentData,
    editEquipmentData,
    approveEquipmentData,
    rejectEquipment,
    mailWithOutAttachment,
  } = API;
  const [poVisible,setPoVisible]=useState(false);
  const { height, width } = useWindowDimensions();
  const { customerList, yardLocationData } = props;
  const [remark, setRemark] = useState('');
  const [assetId, setAssetId] = useState('');
  const [fileList, setFileList] = useState(null);
  const [loading, setLoading] = useState(true);
  const { latitude, longitude } = usePosition();
  const [eqCategory,setEqCategory]=useState(null)
  const [eqTypeData,setEqTypeData]=useState(null);
  const [eqType,setEqType]=useState(null);
  const [eqNumber,setEqNumber]=useState(null);
const [confirm,setConfirm]=useState(confirmation())

  const onSearch = (text, value) => {
    eqDropdownUpdate('equipment_no', eqNumber);
    const _value = eqNumber && eqNumber.filter((item) => item.value === text);
    if (_value&&_value.length === 0) {
      setAssetId('');
      disableItems('equipment_type', false, false, true);
      // form.setFieldsValue({ equipment_type: '' });
    } else {
      disableItems('equipment_type', true, false, true);
      disableItems('equipment_no', false, true, true);
      onSerchEquipmentType(_value[0]?.eqNo)
      form.setFieldsValue({ equipment_type: _value[0]?.eqNo });
      setAssetId(_value[0]?.asset_id);
    }
  };


  const onSelect = (data, value) => {
    value &&
      value.map((item) => {
        if (item.value === data) {
          setAssetId(item.asset_id);
          onSerchEquipmentType(item.eqNo)
          form.setFieldsValue({ equipment_type: item.eqNo });
          disableItems('equipment_type', true, false, true);
          // disableItems('equipment_no', false, true, true);
        }
      });
  };

  const onSerchEquipmentType=(eqNo)=>{
      eqTypeData.map((type)=>{
        if(type.value===eqNo){
          form.setFieldsValue({ equipment_category: type.parent_id });    
          dropDownEqCategory(type.parent_id,'new')    
        }
      })
  }
  const EquipmentCategory=()=>{
    const tabParam = JSON.parse(localStorage.getItem('data'));
    const _eqCategory = [];
    const _eqType=[]
    tabParam.TabParams &&
      tabParam.TabParams.map((data) => {
        if (data.param_key === 'equipment_category') {
          _eqCategory.push({
            value: data.param_value.toString(),
            displayValue: data.param_name,
          });
        }else if(data.param_key==="equipment_type"){
          _eqType.push({
            value: data.param_value.toString(),
            displayValue: data.param_name,
            parent_id:data.parent_id.toString()
          })
        }
      });
      setEqTypeData(assendingSort(_eqType))
      setEqCategory(assendingSort(_eqCategory))
  }

  const dropDownEqCategory=(e,type)=>{
    if(type==='update'){
      form.setFieldsValue({
        // equipment_no: '',
        equipment_type:''
      });
    }
    if(e==='1080'){
      disableItems('quantity', false, false, true);
      disableItems('equipment_no', false, true, false);
    }else{
      disableItems('quantity', true, false, true);
      disableItems('equipment_no', false, true, true);
    }
    
    const tabParam = JSON.parse(localStorage.getItem('data'));
    const _eqType = [];
    tabParam.TabParams &&
      tabParam.TabParams.map((data) => {
        if (data.param_key === 'equipment_type'&&e===data.parent_id.toString()) {
          _eqType.push({
            value: data.param_value.toString(),
            displayValue: data.param_name,
            parent_id:data.parent_id.toString()
          });
        }
      });
      setEqType(assendingSort(_eqType))
  }
  const dropDownEqType=(e)=>{
    const eqNo=form.getFieldValue('equipment_no')
   const filterEqNo= eqNumber.filter((item)=>eqNo===item.value)
   if(filterEqNo.length!==0){
    form.setFieldsValue({
      equipment_no: '',
    });
   }
 eqTypeData.map((item)=>{
          if(item.value===e){
      item.eqNo&& item.eqNo.sort((a,b) => (a.value.toUpperCase() > b.value.toUpperCase()) ? 1 : ((b.value.toUpperCase()> a.value.toUpperCase()) ? -1 : 0))
      eqDropdownUpdate('equipment_no', item.eqNo);
    }
      })
  }
  const disableItems = (name, value, require, requireVal) => {
    const eq = [...equipment];
    eq.map((item) => {
      if (item.name === name) {
        item.disabled = value;
        if (require) {
          item.rules.map((rules) => {
            rules.required = requireVal;
          });
        }
      }
    });
    setEquipment(eq);
  };

  useEffect(
    () => {
      getPoListFunc();
    },
    [props.user_id, props.title]
  );
  const getPoListFunc = () => {
    if (props.title === 'Edit User' && props.user_id !== undefined) {
      getUserDetails(props.user_id).then((res) => {
        const _data = {
          ...res.data.data,
          role_id: res.data.data.role_id.toString(),
        };
        form.setFieldsValue(_data);
      });
    }
  };

  useEffect(
    () => {
      form.resetFields();
      const callback = (val) => {};
        if (!props.asset_txns_id) {
          setFileList([]);
        }
        getEqDetails();
        const tabParam = JSON.parse(localStorage.getItem('data'));

        setClientDetail({
          clientName: tabParam.first_name,
          clientCompany: tabParam.clientCompany,
          email: tabParam.email,
          phone: tabParam.mobile,
        });
        const eqType = [];
        tabParam.TabParams &&
          tabParam.TabParams.map((data) => {
            if (data.param_key === 'equipment_type') {
              eqType.push({
                value: data.param_value.toString(),
                displayValue: data.param_name,
              });
            }
          });
        eqDropdownUpdate('equipment_type', eqType);
        props.fetchYardList();
        props.CustomerList(callback);
        EquipmentCategory()
      
    //   else {
    //     setFileList(null);
    //     setLoading(true);
    //   }
    },
    []
  );

  const getEqDetails = () => {
    if (props.asset_txns_id) {
      getEquipmentData(props.asset_txns_id).then((res) => {
        setLoading(false);
        const _data = res.data.data;
     
        const tabParam = JSON.parse(localStorage.getItem('data'));
      const equipmentType=  tabParam.TabParams.filter((item)=>item.param_value===_data.equipment_type)
      dropDownEqCategory(equipmentType[0]?.parent_id?.toString(),'new') 
      const imageArr = [];
        if (_data.assetDocs) {
          _data.assetDocs.map((item) => {
            imageArr.push({
              doc_id: item.doc_id,
              uid: item.doc_id,
              name: item.doc_name,
              status: 'done',
              url: item.doc_path,
              thumbUrl: item.doc_path,
            });
          });
        }
        setFileList(imageArr);
        form.setFieldsValue(_data);
        getPoList(_data.client_cust_id);
        setAssetId(_data.asset_id);
        let val = {
          equipment_category:equipmentType[0].parent_id.toString(),
          client_cust_id: _data.client_cust_id.toString(),
          equipment_type: _data.equipment_type.toString(),
          location_id: _data.location_id.toString(),
          po_id: _data.po_id ? _data.po_id.toString() : '',
        };
        var str = _data.last_known?_data.last_known:"";
        var res = str.split('-');
        if (res[0] === 'nonhaz') {
          handleSource('hazardous');
          val = {
            ...val,
            hazardous: str.replace('nonhaz-', ''),
            last_known: 'nonhaz',
          };
        } else if (res[0] === 'haz') {
          handleSource('hazardous');
          val = {
            ...val,
            hazardous: str.replace('haz-', ''),
            last_known: 'haz',
          };
        } else if (_data.last_known === 'water') {
          handleSource('hazardous');
          val = {
            ...val,
            hazardous: _data.last_known,
            last_known: 'nonhaz',
          };
        } else {
          handleSource('hazardous');
          val = {
            ...val,
            hazardous: _data.last_known,
            last_known: 'haz',
          };
        }
        form.setFieldsValue(val);
        disableInput();
        disableItems('client_cust_id', true, false, true);
        disableItems('equipment_type', true, false, true);
        disableItems('equipment_no', true, false, true);
        disableItems('po_id', false, true, false);
      });
    } else {
      disableItems('po_id', false, true, false);
      disableItems('equipment_type', false, true, true);
    }
  };

  useEffect(
    () => {
      const customer = customerList;
      const clientCustomer = []
      customer &&
        customer.map((data) => {
          clientCustomer.push({
            value: data.clients_cust_id.toString(),
            displayValue: data.name,
          });
        });
      eqDropdownUpdate('client_cust_id',assendingSort(clientCustomer));
    },
    [props.customerList]
  );
  const eqDropdownUpdate = (name, value) => {
    const eq = [...equipment];
    let val = value
    if(name ==='equipment_type'){
      assendingSort(val)
      // val.sort((a,b) => (a.displayValue > b.displayValue) ? 1 : ((b.displayValue> a.displayValue) ? -1 : 0))
    }
    
    
    eq.map((item) => {
      if (item.name === name) {
        item.value = val;
      }
    });
  
    setEquipment(eq);
  };
  useEffect(
    () => {
      const yardArr = [];
      yardLocationData &&
        yardLocationData.map((data) => {
          yardArr.push({
            value: data.id.toString(),
            displayValue: TextCapitalize(data.name),
            coordinates: data.coordinates,
          });
        });
      eqDropdownUpdate('location_id',assendingSort(yardArr));
    },
    [yardLocationData]
  );


  const dropDownSelect = (e, value,dropValue) => {
    if (value === 'client_cust_id') {
      form.setFieldsValue({
        equipment_no: '',
        equipment_type: '',
        po_id: '',
        location_id: '',
      });
      disableItems('equipment_type', false, false, true);
      assetSearch(e).then((res) => {
        const data = res.data.data;
        const eqNo = [];
        data &&
        data.map((_data) => {
          eqNo.push({
            value: _data.equipment_no.toString(),
            eqNo: `${_data['equipment_type_details.param_value']}` ,
            asset_id: _data.asset_id,
          });
        });
        eqNo&&eqNo.sort((a,b) => (a.value.toUpperCase() > b.value.toUpperCase()) ? 1 : ((b.value.toUpperCase()> a.value.toUpperCase()) ? -1 : 0))
        setEqNumber(eqNo)
    
   const _eqType=  [...eqTypeData]
   _eqType.map((_item)=>{
       _item.eqNo=[]
      const arr=[]
     eqNo.map((eq)=>{
       if(eq.eqNo===_item.value){
         arr.push(eq)
       }
       _item.eqNo=arr
     })
     }) 
     setEqTypeData(eqTypeData)
        eqDropdownUpdate('equipment_no', eqNo);
      });
      getPoList(e);
    } 
  };
  const getPoList = (e) => {
    poNumber(e).then((res) => {
      const _data = res.data.data;
      const po = [];
      _data &&
        _data.map((data) => {
          po.push({
            value: data.po_id.toString(),
            displayValue: data.po_no.toUpperCase(),
          });
        });
      eqDropdownUpdate('po_id', assendingSort(po));
    });
  };

  const handleSource = (e) => {
    disableItems('hazardous', false, true, true);
  };
  const onFinish = (values) => {
    setBtnDisable(true);
    let coordinates = [];
    const location = form.getFieldValue('location_id');

    const last_known = form.getFieldValue('last_known');
    if (last_known === 'haz') {
      values = { ...values, last_known: 'haz-' + values.hazardous };
    } else {
      values = { ...values, last_known: 'nonhaz-' + values.hazardous };
    }
    let yardDisplay;
    equipment.map((item) => {
      if (item.name === 'location_id') {
        item.value.map((cord) => {
          if (cord.value === location) {
            yardDisplay = cord.displayValue;
            coordinates = cord.coordinates;
          }
        });
      }
    });

    values = {
      ...values,
      po_id: values.po_id ? parseInt(values.po_id) : '',
      location_id: parseInt(values.location_id),
      equipment_type: parseInt(values.equipment_type),
      client_cust_id: parseInt(values.client_cust_id),
    };
    if (assetId) {
      values = { ...values, asset_id: assetId };
    }

    if (props.asset_txns_id) {
      delete values.file;
      const val = { ...values, asset_txns_id: props.asset_txns_id,estimate: 0 };
      editEquipmentData(val)
        .then((res) => {
          const approve = {
            txns_id: props.asset_txns_id,
            coordinates: coordinates,
            estimate: 0,
          };
          if (res.data.success === true) {
            approveEquipmentData(approve)
              .then((response) => {
                if (response.data.success === true) {
                  form.resetFields();
                  setBtnDisable(false);
                  props.deleteByAssetTxnsId('assetList',props.asset_txns_id);
                  props.getInYardList(0,'');
                  setVisible(false);
                  props.getWashStatus();
                  props.getDashboardCount();
                  props.getTicketAwaiting();
                  message.success('Approved');
                } else {
                  setBtnDisable(false);
                  message.error(response.data.message);
                }
              })
              .catch((err) => {
                setBtnDisable(false);
                message.error(err?.response?.data.message);
              });
          } else {
            message.error(res.data.message);
            setBtnDisable(false);
          }
        })
        .catch((err) => {
          message.error(err?.response?.data.message);
          setBtnDisable(false);
        });
    } else {
      const fileArr = [];
      values.file &&
        values.file.map((res) => {
          fileArr.push(res.response.data.filepath);
        });
      equipmentCheckin({ ...values, coordinates: coordinates, sds: fileArr })
        .then(async (res) => {
          if (res.data.success === true) {
            props.getAssetList(0,'');
            props.getInYardList(0,'');
            form.resetFields();
            setBtnDisable(false);
            props.getWashStatus();
            props.getDashboardCount();
            props.getTicketAwaiting();
            setVisible(false);
            if (values.email) {
              await mailWithOutAttachment({
                to: values.email,
                from: 'info@washtrax.com',
                subject: 'EQUIPMENT DROP-OFF',
                client_manager: clientDetail.clientName,
                client_name: clientDetail.clientCompany,
                client_manager_email: clientDetail.email,
                client_manager_phone: clientDetail.phone,
                type: 'dropoff',
                equipment_no: values.equipment_no,
                yardAddress: yardDisplay,
              });
            }
            message.success('Succesfully Added & Checked In Asset');
          } else {
            setBtnDisable(false);
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          message.error(err?.response?.data.message);
          setBtnDisable(false);
        });
    }
  };

  const disableInput = () => {
    const customerId = form.getFieldValue('client_cust_id');
    if (props.status === 'Rejected') {
      return true;
    } else if (!customerId) {
      return true;
    } else {
      return false;
    }
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const uploadImg = (info) => {
    if (info.file.status === 'uploading') {
      setBtnDisable(true)
    }
    if (info.file.status === 'done') {
      if (props.asset_txns_id) {
        const local = JSON.parse(localStorage.getItem('data'));
        let doc_type = '';
        local.TabParams.map((item) => {
          if (item.param_description === 'Safety Sheets (SDS)') {
            doc_type = item.param_value;
          }
        });

        const fileData = {
          doc_path: info.file.response.data.filepath,
          coordinates: [latitude??	0.00, longitude??	0.00],
          asset_txns_id: props.asset_txns_id,
          doc_type: doc_type,
          doc_name: 'Safety Sheets (SDS)',
        };

        imageUpdate(fileData).then((res) => {
          const _data = res.data.data;
          const imageArr = [...fileList];
          if (_data) {
            imageArr.push({
              doc_id: _data.doc_id,
              uid: _data.doc_id,
              name: _data.doc_name,
              status: 'done',
              url: _data.doc_path,
              thumbUrl: _data.doc_path,
            });
          }
          setFileList(imageArr);
        });
      } 
      setBtnDisable(false)
      // message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      setBtnDisable(false);
      // message.error(`${info.file.name} file upload failed.`);
    } else if (info.file.status === 'removed') {
      if (props.asset_txns_id) {
        const imageArr = [...fileList];
        let url = {};
        let uid = '';
        imageArr.map((item, i) => {
          if (!info.file.response) {
            if (item.url === info.file.url) {
              uid = item.doc_id;
              url = { url: item.url };
              imageArr.splice(i, 1);
            }
          } else if (info.file.response.data.filepath === item.url) {
            uid = item.doc_id;
            url = { url: item.url };
            imageArr.splice(i, 1);
          }
        });
        setFileList(imageArr);

        imageDelete(uid, url).then((res) => {});
      }
    }
  };


  const confirmReject = () => {
    const data = { asset_txns_id: props.asset_txns_id, remark: remark };
    rejectEquipment(data).then((res) => {
      setRejectPop(false);
      setRemark('');
      props.getAssetList(props.assetPage,'');
      setVisible(false);
    });
  };
  const popCancel = () => {};
  return (
    // <Footer
    //   fullWidth={width<991?true:false}
    //   setVisible={(e) => setVisible(e)}
    //   visible={visible}
    //   Button={props.Button}
    //   custom={props.custom}
    //   title={
    //     props.title +
    //     `${props.status === 'Rejected' ? ' (Ticket Rejected)' : ''}`
    //   }
    //   buttonColor={props.buttonColor}
    //   submit={
    //     <div>
    //       {
    //         <PopOver
    //           remark={remark}
    //           rejectPop={rejectPop}
    //           setRemark={(e) => setRemark(e.target.value)}
    //           confirmReject={() => confirmReject()}
    //           setRejectPop={(e) => setRejectPop(e)}
    //         />
    //       }
    //       <Form.Item>
    //         {props.asset_txns_id && (
    //           <Button
    //             disabled={btnDisable || disableInput()}
    //             type="default"
    //             onClick={() => setRejectPop(true)}
    //           >
    //             Reject Equipment
    //           </Button>
    //         )}
    //         <Button
    //           disabled={btnDisable || disableInput()}
    //           type="primary"
    //           form={
    //             props.asset_txns_id ? (
    //               `approve${props.asset_txns_id.toString()}`
    //             ) : (
    //               'checkin'
    //             )
    //           }
    //           htmlType="submit"
    //         >
    //           {props.asset_txns_id ? 'Release To Yard' : 'Submit'}
    //         </Button>
    //       </Form.Item>
    //     </div>
    //   }
    // >
       <AppLayout>
      <Form
        form={form}
        id={
          props.asset_txns_id ? (
            `approve${props.asset_txns_id.toString()}`
          ) : (
            'checkin'
          )
        }
        name="register"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row gutter={24}>
          {equipment &&
            equipment.map((_form) => (
              _form.name==="custom1"?<Col xs={{ span: 24}} lg={{ span: 12}}>
              <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item
                        //  getValueFromEvent={_form.type === 'upload' ? normFile : ''}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="equipment_category"
                      rules={[
                            {
                              required: true,
                              message: 'Please Select equipment category!',
                              whitespace: true,
                            },
                          ]}
                      label={<span>EQUIPMENT TYPE</span>}
                    >
                      <Select
                          disabled={disableInput()}
                          showSearch
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        virtual={false}
                          allowClear={false}
                          placeholder={`CATEGORY `}
                          onChange={(e) => dropDownEqCategory(e,'update')}
                        >
                         {eqCategory&&eqCategory.map((item)=>(
                          <Option value={item.value}>
                                {item.displayValue}
                              </Option>
                         ))
                              
                            }
                        </Select>
                    </Form.Item>
                    </Col>
                    <Col span={12}>
      
    
                                  <Form.Item
                                   requiredMark="optional"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="equipment_type"
                      rules={ [
                            {
                              required: true,
                              message: 'Please Select equipment type!',
                              whitespace: true,
                            },
                          ]}
                      label={<span></span>}
                    >
                      <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                          disabled={disableInput()}
                        virtual={false}
                          allowClear={false}
                          placeholder={`SUB CATEGORY`}
                          onChange={(e) => dropDownEqType(e)}
                        >
                            <Option value="" disabled>
                            SUB CATEGORY
                          </Option>
                         
                             {eqType&&eqType.map((item)=> <Option value={item.value}>
                                {item.displayValue}
                              </Option>)}
                            
                        </Select>
                    </Form.Item>
                    </Col>
                    </Row>
                    </Col>
              
             :
             _form.name==="custom2"?
             <Col span={24}>
             <Row gutter={24}>
               <Col span={12}>
             <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="remark"
                      rules={[]}
                      label={<span>DETAILS FOR BULK ITEMS/ REMARKS</span>}
                    >
                      <Input.TextArea
                        disabled={disableInput()}
                        style={{ height: 135 }}
                      />
                    </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Row gutter={12}>

              <Col span={24}>
             <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="owner"
                      rules={[]}
                      label={<span>OWNER</span>}
                    >
                      <Input
                          disabled={disableInput()}
                      placeholder={_form.placeholder}
                    />
                    </Form.Item>
                    </Col>

                    <Col span={24}>
             <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="manifest"
                      rules={[]}
                      label={<span>MANIFEST#</span>}
                    >
                      <Input
                          disabled={disableInput()}
                      placeholder={_form.placeholder}
                    />
                    </Form.Item>
                    </Col>


                    </Row>
                    </Col>
                  

                    </Row>
             </Col>
             :
              <Col xs={{ span: 12}} lg={{ span: _form.col}}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name={_form.name}
                  rules={_form.rules}
                  label={<span>{_form.lable}</span>}
                  getValueFromEvent={_form.type === 'upload' ? normFile : ''}
                  initialValue={_form.initialValue ? _form.initialValue : ''}
                  // initialValue={_form.initialValue?_form.initialValue:''}
                >
                  { _form.type === 'dropdown' ? (
                    <Select
                    virtual={false}
                      disabled={
                        (_form.name !== 'client_cust_id' && disableInput()) ||
                        _form.disabled
                      }
                      allowClear={false}
                      placeholder={`SELECT ${_form.lable}`}
                      style={_form.style}
                      onChange={(e) => dropDownSelect(e, _form.name,_form.value)}
                    >
                      {_form.value &&
                        _form.value.map((item) => (
                          <Option value={item.value}>
                            {item.displayValue}
                          </Option>
                        ))}
                    </Select>
                  ) : _form.type === 'radio' ? (
                    <Radio.Group
                      disabled={disableInput()}
                      onChange={(e) => handleSource(e.target.value)}
                    >
                      {_form.value.map((item) => (
                        <Radio value={item.value}>{item.displayValue}</Radio>
                      ))}
                    </Radio.Group>
                  ) : _form.type === 'textArea' ? (
                    <Input.TextArea
                      disabled={disableInput()}
                      style={{ height: 87 }}
                    />
                  ) : _form.type === 'autocomplete' ? (
                    <AutoComplete
                      disabled={disableInput() || _form.disabled}
                      options={_form.value}
                      onSelect={(e) => onSelect(e, _form.value)}
                      onSearch={(e) => onSearch(e, _form.value)}
                      placeholder="input here"
                    />
                  ) : (
                    <Input disabled={_form.disabled || disableInput()} />
                  )}
                </Form.Item>
                {_form.name === 'po_id' &&<AddPo disabled={disableInput()} client_cust_id={form.getFieldValue('client_cust_id')} updatePo={()=>getPoList(form.getFieldValue('client_cust_id'))} data={equipment} visible={poVisible} setVisible={(e)=>setPoVisible(e)}/>}
              </Col>
            ))}
 
          {/* {props.asset_txns_id && <Col span={12} />} */}

         <SDS file={file} disableInput={disableInput()} uploadImg={uploadImg} normFile={normFile} fileList={fileList}/>
          <Col span={8}>
          {!props.asset_txns_id &&
            confirm.map((_form) => (
              _form.name==="sds"?<SDS file={file} disableInput={disableInput()} uploadImg={uploadImg} normFile={normFile} fileList={fileList}/>:
              <Col span={_form.col}>
                <Form.Item
                  labelCol={{span: 24}}
                  wrapperCol={{span: 24}}
                  name={_form.name}
                  label={<span>{_form.lable}</span>}
                  initialValue={_form.initialValue ? _form.initialValue : ''}
                  rules={_form.rules}
                >
                 <Input
                  disabled={disableInput}
                  placeholder={_form.placeholder}
                />
                 
                </Form.Item>
              </Col>
            ))}
            </Col>

          {/* {props.asset_txns_id && (
            <Col span={estimate().col}>
              <Form.Item
                // labelCol={{ span: 24 }}
                // wrapperCol={{ span: 24 }}
                name={estimate().name}
                rules={estimate().rules}
                label={<span style={{ fontSize: 20 }}>{estimate().lable}</span>}
              >
                <InputNumber
                  disabled={disableInput()}
                  style={{ width: '15.5%', fontSize: 18 }}
                  min={0}
                  precision={2}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Col>
          )} */}
        </Row>
      </Form>
      {props.asset_txns_id &&
      loading && (
        <div className="eq-spinner">
          <Spin size="large" />
        </div>
      )}
      <style>
        {`
.drop-off{
justify-content: flex-start;
flex-direction: row;
display: flex;
}
.drop-off div{
display: flex;
justify-content: center;
align-items: center;
}
.pop-over-container{
  position: relative;
    display: flex;
    justify-content: center;
}
.pop-over{
  position: absolute;
  background: white;
  padding: 29px;
  width: 99%;
  max-width: 589px;
  bottom: 70px;
  border: 1px solid #c1baba;
  border-radius: 13px;
}
.eq-spinner{
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
    background: rgba(0,0,0,0.3);
}
`}
      </style>
      </AppLayout>
  );
};

export class Register extends Component {
  render() {
    return <RegistrationForm {...this.props} />;
  }
}
const mapStateToProps = (state) => ({
  yardLocationData: state.yardLocationData.yardLocationData,
  customerList: state.customer.customerList,
  assetPage:state.myYard.assetPage,
  inYardPage:state.myYard.inYardPage,
});

export default connect(mapStateToProps, {
  fetchYardList,
  CustomerList,
  getAssetList,
deleteByAssetTxnsId,
  getInYardList,
  getWashStatus,
  getDashboardCount,
  getTicketAwaiting
})(Register);


const SDS=({file,disableInput,uploadImg,normFile,fileList})=>{
  return(
    <Col span={file.col}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name={file.name}
              label={file.lable}
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              {fileList && (
                <Upload
                disabled={ disableInput}
                  name={file.name}
                  action={file.action}
                  defaultFileList={fileList ? fileList : []}
                  onChange={uploadImg}
                  headers={file.headers}
                  listType="picture"
                >
                  <Button   disabled={ disableInput} icon={<UploadOutlined />}>
                    Click to upload
                  </Button>
                </Upload>
              )}
            </Form.Item>
            {/* <ImageCompression/> */}
          </Col>
  )
}
